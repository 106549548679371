.container {
  display: grid;
  /* background-color: #2f354a; */
  background-image: url("../../../Image/background_sisea.png");
  user-select: none;
}

.loginForm {
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content;
  grid-gap: 30px;
  user-select: none;
}

.formControl {
  display: grid;
  max-width: 360px;
  height: 56px;
  user-select: none;
}

.loginBtn {
  width: 224px;
  height: 36px;
  justify-self: center;
  border-radius: 5px;
  user-select: none;
}

.remember {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: min-content;
  justify-self: center;
  user-select: none;
}

.rememberLogin {
  grid-column: 1;
  grid-row: 1;
  justify-self: start;
  align-self: center;
  user-select: none;
}

.rememberTitle {
  font-size: 0.825rem;
  font-weight: 500;
  margin-right: 15px;
  user-select: none;
}

.forgotPassword {
  grid-column: 2;
  grid-row: 1;
  justify-self: end;
  align-self: center;
  margin-right: 15px;
  text-decoration: none;
  color: #125ad3;
  font-size: 0.825rem;
  user-select: none;
}

.forgotPassword:hover {
  text-decoration: underline;
  user-select: none;
}

.loginLogo {
  width: 220px;
  justify-self: center;
  margin: 40px 0 20px;
  user-select: none;
}

.titleLogin {
  justify-self: center;
  font-size: 1rem;
  color: #373e56;
  user-select: none;
}

.errorLogin {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-self: center;
  grid-gap: 10px;
  margin: 0 20px;

  user-select: none;
}

.iconError {
  grid-column: 1;
  user-select: none;
}

.errorText {
  grid-column: 2;
  margin: 0;
  color: #f44336;
  user-select: none;
}
