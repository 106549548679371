.sidebarContainer {
  min-width: 230px;
  user-select: none;
}

.sidebarSup {
  grid-row: 1;
  grid-template-columns: auto;
  display: grid;
  min-height: 150px;
  background-color: transparent;
  user-select: none;
}

.sidebarIconTitle {
  grid-template-columns: min-content min-content;
  display: grid;
  align-self: center;
  justify-self: center;
  user-select: none;
}

.sidebarSupTitle {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: center;
  white-space: nowrap;
  font-size: 1.2em;
  color: #1a5dce;
  font-weight: 600;
  margin: 5px 0;
  user-select: none;
}

.movementType {
  width: 180px;
  grid-column: 1;
  grid-row: 2;
  align-self: center;
  justify-self: center;
}

.sidebarInf {
  grid-row: 2;
  background-color: #fafafa;
  overflow: auto;
  height: calc(var(--vh, 1vh) * 100 - 274px);
  user-select: none;
}

.filterGroup {
  grid-row: 2;
  grid-template-columns: max-content max-content;
  display: grid;
  align-self: center;
  justify-self: center;
  grid-gap: 10px;
  margin: 10px;
  user-select: none;
}

.filterTitle {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: start;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #565656;
}

.pickerPeriod {
  display: grid;
  width: min-content;
  margin-bottom: 10px;
  user-select: none;
}

.filter {
  grid-row: 3;
  user-select: none;
}

@media only screen and (max-width: 768px) {
  .sidebarSupTitle {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .sidebarSupTitle {
    font-size: 1.125rem;
  }
}
