.dashboardMain {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 400px);
  height: calc(var(--vh, 1vh) * 100 - 90px);
  overflow: auto;
  user-select: none;
}

.dashboardContentLeft {
  grid-column: 1;
  display: block;
  padding: 20px 10px 20px 20px;
  user-select: none;
}

.chartsArea {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
  justify-content: space-around;
  user-select: none;
}

.chartsMultiArea {
  grid-row: 2;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: min-content;
  grid-gap: 20px;
}

.dashboardContentRight {
  grid-column: 2;
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 20px;
  padding: 20px 20px 20px 10px;
  user-select: none;
}

.infoAreaDesktop {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  justify-content: space-around;
  user-select: none;
}

.infoAreaMobile {
  display: none;
  margin-bottom: 20px;
}

.infoAreaBar {
  grid-column: 1;
  grid-row: 2;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: min-content min-content min-content;
  user-select: none;
}

@media only screen and (min-width: 601px) and (max-width: 900px) {
  .dashboardMain {
    grid-template-columns: 1fr 300px;
  }
  .infoAreaMobile {
    display: none;
  }
  .chartsMultiArea {
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
}

@media only screen and (max-width: 600px) {
  .dashboardMain {
    height: calc(var(--vh, 1vh) * 100 - 90px);
    grid-template-columns: 1fr;
  }
  .dashboardContentLeft {
    padding: 0px;
    padding-top: -10px;
    padding-bottom: 20px;
  }
  .dashboardContentRight {
    grid-column: 1;
    padding: 0px;
    padding-top: -10px;
    padding-bottom: 20px;
    grid-gap: 0;
  }
  .infoAreaBar {
    width: 100%;
  }
  .infoAreaDesktop {
    display: none;
  }
  .infoAreaMobile {
    grid-column: 1;
    grid-row: 2;
    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr repeat(3, min-content);
    justify-content: space-around;
  }
  .chartsArea {
    grid-column: 1;
    grid-row: 3;
  }
  .chartsMultiArea {
    grid-column: 1;
    grid-row: 4;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
  .infoAreaBar {
    grid-column: 1;
    grid-row: 5;
  }
}

@media only screen and (max-height: 480px) {
  .dashboardMain {
    height: calc(var(--vh, 1vh) * 100 - 90px);
    grid-template-columns: 1fr;
  }
  .dashboardContentLeft {
    padding: 0px;
    padding-top: -10px;
    padding-bottom: 20px;
  }
  .dashboardContentRight {
    grid-column: 1;
    padding: 0px;
    padding-top: -10px;
    padding-bottom: 20px;
    grid-gap: 0;
  }
  .infoAreaBar {
    width: 100%;
  }
  .infoAreaDesktop {
    display: none;
  }
  .infoAreaMobile {
    grid-column: 1;
    grid-row: 2;
    grid-template-columns: 1fr repeat(3, min-content);
  }
  .chartsArea {
    grid-column: 1;
    grid-row: 3;
  }
  .chartsMultiArea {
    grid-column: 1;
    grid-row: 4;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
  }
  .infoAreaBar {
    grid-column: 1;
    grid-row: 5;
  }
}
