.main {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin: 20px 0 40px;
}

.header {
  display: grid;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 5px;
  background-color: #e8e8e8;
}

.item {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 15px;
}

.contentSubject {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  font-size: 0.9rem;
  margin: 0 50px 10px 50px;
}

.subject {
  grid-row: 2;
  grid-column: 1;
  font-size: 1.125rem;
  font-weight: 400;
}

.itemDate {
  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-gap: 15px;
}

.allDay {
  grid-row: 2;
  grid-column: 1;
}

.date {
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px 20px;
}

.recorrency {
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content min-content;
}

.recorrencyTitle {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.625rem;
  margin-left: 5px;
  font-weight: 600;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}

.location {
  grid-row: 2;
  grid-column: 1;
}

.notes {
  grid-row: 2;
  grid-column: 1;
  line-height: 1.5;
  word-wrap: anywhere;
}

.assigned {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
}
.titleAssigned {
  grid-row: 1;
  grid-column: 1;
}

.content {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
  display: grid;
}

.label {
  grid-row: 1;
  font-size: 0.625rem;
  color: #737373;
}
