.header {
  display: grid;
}

.searchContent {
  display: grid;
  margin: 10px 0 20px;
}

.searchHeader {
  display: grid;
  grid-template-columns: 20px 40px 190px;
  margin-bottom: 10px;
  grid-gap: 10px;
}

.contentTitle {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  font-size: 1rem;
  font-weight: 600;
  color: #737373;
  user-select: none;
}

.fieldSearch {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
  margin-left: 20px;
}

.searchList {
  display: grid;
  overflow-y: auto;
  max-height: 300px;
  background-color: #fbfbfb;
  padding: 4px 6px;
  user-select: none;
}

.itemSearchList {
  display: grid;
  grid-column: 1;
  grid-template-columns: 1fr max-content;
  grid-gap: 15px;
  user-select: none;
}

.content {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  color: #737373;
  font-size: 0.9rem;
}
