.main {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 30px;
  margin: 20px 0;
}

.header {
  display: grid;
  grid-template-columns: 40px 1fr;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}

.itemSubjectDate {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 15px;
  margin-bottom: 15px;
}
.color {
  grid-row: 1 / span 2;
  grid-column: 1;
  width: 5px;
  height: 100%;
  border-radius: 2px;
  margin: 0 7.5px;
}

.subject {
  grid-row: 1;
  grid-column: 2;
  font-size: 1.125rem;
  font-weight: 600;
}

.date {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-gap: 5px;
}

.recorrency {
  grid-row: 3;
  grid-column: 2;
  display: grid;
  grid-template-columns: 20px 1fr;
}

.recorrencyTitle {
  grid-column: 2;
  align-self: center;
  justify-self: start;
  font-size: 0.625rem;
  margin-left: 5px;
  font-weight: 600;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.54);
}

.mode {
  grid-row: 2;
  grid-column: 1;
}

.location {
  grid-row: 2;
  grid-column: 1;
}

.notes {
  grid-row: 2;
  grid-column: 1;
  line-height: 1.5;
  word-wrap: anywhere;
  white-space: pre-line;
}

.clientName {
  grid-row: 2;
  grid-column: 1;
}

.processFolder {
  grid-row: 2;
  grid-column: 1;
}

.process {
  grid-row: 2;
  grid-column: 1;
}

.assigned {
  grid-row: 2;
  grid-column: 1;
}

.linkedUsers {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: 20px;
}

.linkedUsersLink {
  grid-row: 1;
  grid-column: 2;
  justify-self: start;
  align-self: center;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  width: max-content;
  height: 16px;
  padding: 4px;
  cursor: pointer;
}

.linkedUsersLink:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px;
  border-radius: 4px;
}

.createBy {
  grid-row: 2;
  grid-column: 1;
}

.statusQuestion {
  grid-column: 1;
  align-self: center;
  justify-self: start;
  font-size: 0.875rem;
}

.toggleButtonGroup {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-column: 2;
  align-self: center;
  justify-self: end;
}

.statusTitle {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.625rem;
  margin-left: 5px;
}

.dialogTitle {
  margin: 20px;
  text-align: center;
}

.dialogTitleLinked {
  text-align: center;
  font-weight: 400;
  font-size: 0.8rem;
  align-self: center;
  justify-self: center;
  margin: 0 20px;
}

.dialogLinkedUsers {
  display: grid;
  overflow-y: auto;
  max-height: 300px;
  grid-gap: 10px;
  user-select: none;
}
.itemLinked {
  display: grid;
  grid-column: 1;
  grid-template-columns: 1fr max-content;
  grid-gap: 15px;
  user-select: none;
}

.linkedUserList {
  list-style: none;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.viewContact {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  width: max-content;
  height: 16px;
  padding: 4px;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  align-self: start;
  justify-self: end;
}

.viewContact:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px;
  border-radius: 4px;
  user-select: none;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 5px;
  background-color: #e8e8e8;
}

@media only screen and (max-width: 600px) {
  .statusQuestion {
    grid-column: 1;
    align-self: center;
    justify-self: center;
    font-size: 0.875rem;
  }

  .toggleButtonGroup {
    grid-column: 1;
    justify-self: center;
  }
}
.content {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
  display: grid;
}

.label {
  grid-row: 1;
  font-size: 0.625rem;
  color: #737373;
}

.textContent {
  grid-row: 2;
  font-size: 0.9rem;
  line-height: 1.5;
  word-wrap: anywhere;
  white-space: pre-line;
}
