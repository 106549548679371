.header {
  display: grid;
}

.item {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 15px;
}

.searchHeader {
  display: grid;
  grid-template-columns: 185px 170px;;
  margin: 10px 0;
}

.folderNumberSearch {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
  margin-left: 20px;
}

.folderSearch {
  grid-row: 2;
  grid-column: 1;
}

.clientSearch {
  display: grid;
  grid-gap: 15px;
}

.panelSup {
  grid-row: 1;
  display: grid;
  background-color: #fbfbfb;
  padding: 24px;
  grid-gap: 10px;
}

.client {
  grid-column: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 15px;
  user-select: none;
}

.clientName {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
}

.folder {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 15px;
}

.folderNumber {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 5px;
  background-color: #e8e8e8;
}

.panelInf {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin: 20px 0;
  padding: 8px 24px;
}

.itemDate {
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 15px;
}

.date {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px 20px;
}

.contentTime {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 100px 150px;
  grid-gap: 10px;
  font-size: 0.9rem;
}

.labelTime {
  grid-row: 1;
  font-size: 1rem;
  color: #737373;
}

.time {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  margin-right: 20px;
}

.timeHour {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 35px 35px;
  grid-gap: 20px;
}

.contentTotalTime {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  font-size: 0.9rem;
}

.totalTime {
  grid-row: 2;
  grid-column: 1;
}

.timeSelect {
  grid-row: 3;
  grid-column: 1 / span 2;
  display: grid;
  grid-auto-columns: 36px;
  grid-gap: 10px;
}

.titleIconTime {
  width: 30px;
}

.timeReset {
  grid-row: 4;
  grid-column: 1 / span 2;
  display: grid;
  grid-auto-columns: 20px 1fr;
  grid-gap: 5px;
  font-size: 0.75rem;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  width: max-content;
  height: 16px;
  padding: 4px 6px;
  cursor: pointer;
  justify-self: start;
  user-select: none;
}

.timeReset:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px 6px;
  border-radius: 4px;
  user-select: none;
}

.titleIconReset {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.825rem;
}

.discrimination {
  grid-row: 2;
  grid-column: 1;
  line-height: 1.5;
  word-wrap: anywhere;
}

.itemValue {
  display: grid;
  grid-auto-columns: 20px 150px 100px;
  grid-gap: 15px;
}

.value {
  grid-row: 2;
  grid-column: 1;
}

.itemChargeable {
  grid-column: 3;
  grid-row: 1;
  display: grid;
  grid-template-columns: 20px 1fr;
}

.labelChargeable {
  grid-row: 1;
  text-align: center;
  font-size: 0.625rem;
  color: #737373;
}

.chargeable {
  grid-row: 2;
  grid-column: 1;
}

.content {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
  display: grid;
}

.label {
  grid-row: 1;
  font-size: 0.625rem;
  color: #737373;
}

.dialogTitle {
  margin: 0 5px;
  text-align: center;
  max-width: 300px;
  font-size: 0.9rem;
}

.expansionTitle {
  font-size: 0.8rem;
  color: #e20137;
  font-weight: 600;
}

.expansionInfo {
  list-style: none;
  padding: 0;
  margin: 0;
}

.detailInfo {
  margin-bottom: 5px;
  font-size: 0.8rem;
}
