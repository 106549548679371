.recoveryForm {
  grid-row: 3;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: min-content;
  grid-gap: 30px;
  user-select: none;
}

.titleRecovery {
  grid-row: 1;
  justify-self: center;
  font-size: 1rem;
  color: #373e56;
  line-height: 1.6;
  user-select: none;
}

.fieldsRecovery {
  grid-row: 2;
  display: grid;
  grid-gap: 10px;
  user-select: none;
}

.formControl {
  max-width: 360px;
  height: 56px;
  user-select: none;
}

.errorRecovery {
  grid-row: 3;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  justify-content: center;
  align-self: center;
  grid-gap: 10px;
  margin: 0 20px;
  user-select: none;
}

.iconError {
  grid-column: 1;
  user-select: none;
}

.errorText {
  grid-column: 2;
  margin: 0;
  color: #f44336;
  user-select: none;
}

.recoveryBtn {
  width: 224px;
  height: 36px;
  justify-self: center;
  border-radius: 5px;
  user-select: none;
}

.containerNotice {
  grid-row: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  user-select: none;
}

.noticeRecovery {
  text-align: center;
  justify-self: center;
  align-self: start;
  color: rgba(0, 0, 0, 0.55);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  margin: -10px 0;
  user-select: none;
}

.link {
  grid-row: 5;
  grid-column: 1;
  justify-self: center;
  align-self: start;
  margin: 8px;
  text-decoration: none;
  color: #125ad3;
  user-select: none;
}

.link:hover {
  text-decoration: underline;
  user-select: none;
}
