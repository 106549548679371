.boxContainer {
  grid-row: 1;
  display: none;
  grid-template-columns: 1fr;
  background-color: #fff;
  border-radius: 5px;
  border-right: 1px solid #e1e1e1;
  text-decoration: none;
  width: 65px;
}

.boxBtnIcon {
  margin: 10px 10px 0;
  justify-self: center;
}

.boxBtnTitle {
  font-size: 0.8rem;
  margin: 5px;
  color: #737373;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .boxContainer {
    display: grid;
    border-radius: 0px;
  }

  .boxBtnTimesheets {
    border-bottom: 3px solid #e20137;
  }

  .boxBtnExpenses {
    border-bottom: 3px solid #0899bb;
  }

  .boxBtnProcMovements {
    border-bottom: 3px solid #02cca0;
  }
}

@media only screen and (max-height: 480px) {
  .boxContainer {
    display: grid;
    border-radius: 0px;
  }

  .boxBtnTimesheets {
    border-bottom: 3px solid #e20137;
  }

  .boxBtnExpenses {
    border-bottom: 3px solid #e20137;
  }

  .boxBtnProcMovements {
    border-bottom: 3px solid #02cca0;
  }
}
