.main {
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  max-width: 100%;

  user-select: none;
}

.timesheetToolbar {
  grid-row: 1;
  grid-column: 1 / span 2;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to right, #fbfbfb 0%, #eaeaea 100%);
  pointer-events: none;
  background-size: cover;
  user-select: none;
}

.timesheetMain {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 240px 1fr;
  user-select: none;
}

.timesheetSidebar {
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 100%;
  user-select: none;
}

.timesheetList {
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  padding: 0 20px 0 0;
  z-index: 2;
  user-select: none;
}

.listFull {
  grid-row: 2;
  display: grid;
  grid-template-rows: minmax(40px, auto) 1fr;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  min-height: 0;
  border-radius: 8px 8px 0 0;
  background-color: #fff;
  user-select: none;
}

.listBody {
  grid-row: 2;
  user-select: none;
}

@media only screen and (max-width: 1280px) {
  .timesheetMain {
    grid-template-columns: 1fr;
  }

  .timesheetList {
    grid-column: 1;
    padding: 0 10px;
  }
  .timesheetSidebar {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .timesheetList {
    padding: 0 5px;
  }
}
