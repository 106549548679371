.listItem {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  user-select: none;
}

.ItemDate {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row: 1;
  grid-column: 1;
  align-self: start;
  justify-self: start;
  width: 40px;
  background-color: #02cca0;
  user-select: none;
}

.itemDayMonth {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  color: #fff;
  text-align: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  width: 20px;
  padding: 4px;
  user-select: none;
}

.itemDateYear {
  display: grid;
  background-color: #c5c5c5;
  grid-template-rows: 1fr;
  grid-row: 2;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  width: 100%;
  height: 100%;
  user-select: none;
}

.itemYear {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  justify-self: center;
  padding: 4px;
  font-size: 0.8rem;
  color: #fff;
}

.listItemInfo {
  grid-column: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  align-self: center;
  justify-self: start;
  grid-gap: 5px;
  user-select: none;
}

.itemProcess {
  grid-row: 1;
  grid-column: 1 / span 2;
  font-size: 0.925rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #747474;
  user-select: none;
}

.itemDesc {
  grid-row: 2;
  grid-column: 1 / span 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  width: calc(var(--vw, 1vw) * 100 - 120px);
}

.itemClientIcon {
  grid-row: 3;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  grid-gap: 5px;
}

.itemClientName {
  grid-row: 3;
  grid-column: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemFolderIcon {
  grid-row: 4;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemFolderNumber {
  grid-row: 4;
  grid-column: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.listItemTools {
  display: grid;
  grid-column: 3;
  align-self: center;
  justify-self: end;
  margin-left: 20px;
  user-select: none;
}

.originTitle {
  grid-row: 2;
  font-size: 0.6rem;
  color: #8d8d8d;
}
