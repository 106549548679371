.boxHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: min-content;
  background-color: #f4f4f4;
}

.boxTitle {
  grid-column: 1;
  grid-row: 1;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1.5;
  color: #6c6c6c;
  padding: 10px;
}

.refresh {
  align-self: center;
  margin: 5px;
}

.boxNotice {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6c6c6c;
  text-align: center;
  margin: 10px;
}

.boxTimesheets {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.boxExpenses {
  grid-column: 1;
  grid-row: 2;
  display: grid;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.boxProcMovements {
  grid-column: 1;
  grid-row: 3;
  display: grid;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
}

.boxSkeleton {
  grid-row: 2;
  display: grid;
  grid-template-columns: 20px auto;
  grid-gap: 5px;
}

.sklDate {
  grid-row: 1;
  grid-column: 1;
}

.sklInfo {
  grid-column: 2;
  align-self: center;
  display: grid;
  grid-template-columns: 95%;
}

.listItemTimesheet {
  grid-template-columns: auto auto 1fr min-content;
}

.itemDate {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  width: 50px;
  height: 100%;
  background-color: #e20137;
  user-select: none;
}

.expenseColor {
  background-color: #0899bb;
}

.procMovementColor {
  background-color: #02cca0;
}

.itemDayMonth {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  width: 45px;
  padding: 2.5px;
  user-select: none;
}

.itemDateYear {
  display: grid;
  background-color: #c5c5c5;
  grid-template-rows: 1fr;
  grid-row: 2;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  width: 100%;
  height: 100%;
  user-select: none;
}

.itemYear {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  justify-self: center;
  padding: 4px;
  color: #fff;
  font-size: 0.8rem;
}

.itemTime {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: center;
  width: 40px;
  height: 100%;
  background-color: #f2f2f2;
  user-select: none;
}

.itemTimeSpent {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  width: 40px;
  user-select: none;
}

.itemTimeTitle {
  display: grid;
  grid-template-rows: 1fr;
  grid-row: 2;
  grid-column: 1;
  align-self: start;
  justify-self: center;
  width: 100%;
  height: 100%;
  user-select: none;
}

.timeTitle {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  padding: 2px;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.8rem;
}

.itemInfo {
  grid-column: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row: 1;
  align-self: center;
  justify-self: start;
  grid-gap: 5px;
  margin-left: 10px;
  user-select: none;
}

.itemInfoTimesheet {
  grid-column: 3;
}

.itemDesc {
  grid-row: 1;
  grid-column: 1 / span 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemProcess {
  grid-row: 1;
  grid-column: 1 / span 2;
  font-size: 0.925rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #747474;
  user-select: none;
}

.movementDesc {
  grid-row: 2;
}

.itemExpenseAccount {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  grid-gap: 5px;
}

.expenseAccountTitle {
  grid-row: 1;
  grid-column: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemClientIcon {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  grid-gap: 5px;
}

.itemClientName {
  grid-row: 2;
  grid-column: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemFolder {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-template-rows: 1fr;
  grid-gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.itemFolderNumber {
  grid-row: 2;
  grid-column: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
}

.listItemTools {
  display: grid;
  grid-column: 3;
  grid-row: 1;
  align-self: center;
  justify-self: end;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  user-select: none;
}

.originTitle {
  grid-row: 2;
  font-size: 0.6rem;
  color: #8d8d8d;
}

.itemStatus {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row: 1;
  grid-column: 4;
  align-self: center;
  justify-self: end;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  user-select: none;
}

.itemIconStatus {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  width: 40px;
  user-select: none;
}
