.header {
  display: grid;
  user-select: none;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 5px;
  background-color: #e8e8e8;
}

.panelSup {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  background-color: #f0f0f0;
  padding: 28px 24px;
  user-select: none;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  user-select: none;
}

.content {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 0px 10px;
  font-size: 0.9rem;
}

.label {
  grid-row: 1;
  font-size: 0.625rem;
  color: #737373;
}

.itemContacts {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-gap: 15px 0px;
  margin-bottom: 15px;
  user-select: none;
}

.name {
  grid-row: 1;
  grid-column: 2;
  font-size: 1.125rem;
  font-weight: 600;
  user-select: none;
}

.cargo {
  grid-row: 2;
  grid-column: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  user-select: none;
}

.email {
  grid-row: 3;
  grid-column: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  user-select: none;
}

.emailAdress {
  grid-row: 1;
  grid-column: 2;
}

.phoneNumbers {
  grid-row: 4;
  grid-column: 2;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 15px;
  user-select: none;
}

.phone {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.cellPhone {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.panelInf {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin: 20px 0;
  padding: 8px 24px;
  user-select: none;
}

.contactClass {
  grid-row: 2;
  grid-column: 1;
}

.contactOrigin {
  grid-row: 2;
  grid-column: 1;
}

.viewContactOrigin {
  grid-row: 2;
  grid-column: 2;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  width: max-content;
  height: 16px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
}

.viewContactOrigin:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px;
  border-radius: 4px;
  user-select: none;
}

.docNumber {
  grid-row: 2;
  grid-column: 1;
}

.contentAdress {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  user-select: none;
}

.adress {
  grid-row: 1;
}
.adressComplement {
  grid-row: 2;
}
.adressCity {
  grid-row: 3;
}

.contentTitle {
  grid-row: 1;
  grid-column: 2;
  font-size: 1.1em;
  font-weight: 600;
  color: #737373;
  user-select: none;
}

.listRelatedContacts {
  display: grid;
  overflow-y: auto;
  max-height: 300px;
  grid-gap: 10px;
  user-select: none;
}

.itemRelatedContacts {
  display: grid;
  grid-column: 1;
  grid-template-columns: 1fr max-content;
  grid-gap: 15px;
  user-select: none;
}
.contact {
  grid-row: 1;
  grid-column: 1;
  font-weight: 600;
  color: #535353;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.viewContact {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  width: max-content;
  height: 16px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
}

.viewContact:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px;
  border-radius: 4px;
  user-select: none;
}
