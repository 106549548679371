.listFull {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-rows: 80px auto 1fr;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  min-height: 0;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
  user-select: none;
}

.listHeader {
  grid-row: 1;
  display: grid;

  grid-template-columns: 1fr;
  justify-self: end;
  grid-gap: 0 10px;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  user-select: none;
}

.filterToolbar {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: repeat(4, min-content);
  justify-self: end;
  align-self: center;
  justify-self: start;
  user-select: none;
}

.searchResults {
  grid-row: 2;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  margin: 0 10px;
  user-select: none;
}

.descSearch {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  user-select: none;
  margin: 5px;
}

.descSearchKey {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.5;
  word-break: break-all;
  user-select: none;
}

.orderBy {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: end;
  user-select: none;
  margin: 15px 10px 10px 0;
}

.orderBySelect {
  padding: 20px;
}

.sort {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  justify-self: end;
  user-select: none;
}

.filter {
  grid-row: 1;
  grid-column: 4;
  align-self: center;
  justify-self: end;
  user-select: none;
}

.refresh {
  grid-row: 1;
  grid-column: 5;
  align-self: center;
  justify-self: end;
  user-select: none;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 4px;
  background-color: #e8e8e8;
}

.listBody {
  grid-row: 3;
  display: grid;
  grid-template-rows: max-content;
  user-select: none;
}

.notice {
  grid-row: 1;
  align-self: center;
  justify-self: center;
  margin: 40px 20px;
  user-select: none;
}
