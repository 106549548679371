.listItem {
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr auto;
  user-select: none;
}

.listItemColor {
  grid-column: 1;
  align-self: center;
  justify-self: start;
  width: 3px;
  height: 100%;
  user-select: none;
}

.selectItem {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  width: 40.8px;
}

.listItemBody {
  grid-column: 3;
  display: grid;
  grid-template-rows: repeat(3, auto);
  align-self: stretch;
  justify-self: start;
  margin: 5px 20px 5px 5px;
  user-select: none;
}

.itemSubject {
  grid-row: 1;
  font-size: 0.925rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #747474;
  user-select: none;
  width: calc(var(--vw, 1vw) * 100 - 120px);
}

.itemDesc {
  grid-row: 2;
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.55);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  width: calc(var(--vw, 1vw) * 100 - 120px);
}

.listItemDate {
  display: grid;
  grid-row: 3;
  grid-gap: 15px;
  align-self: stretch;
  justify-self: start;
  user-select: none;
}

.itemDate {
  grid-column: 1;
  align-self: center;
  justify-self: center;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.55);
  line-height: 1.5;
  user-select: none;
}

.itemHour {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.88rem;
  color: rgba(0, 0, 0, 0.55);
  line-height: 1.5;
  user-select: none;
}

.itemRecorrency {
  grid-column: 3;
  align-self: center;
  justify-self: center;
  user-select: none;
}

.listItemTools {
  display: grid;
  grid-column: 6;
  align-self: center;
  justify-self: end;
  user-select: none;
}
