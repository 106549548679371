.selectToolbar {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto;
  height: 50px;
  width: 100%;
  margin: 15px 0;
  border-radius: 4px;
  background-color: #fafafa;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0),
    0px 1px 10px 0px rgba(0, 0, 0, 0.04);
}

.linkedUsersTitle {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
  font-size: 0.875rem;
}

.statusTitle {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  justify-self: center;
  font-size: 0.825rem;
  margin-left: 5px;
}

.status {
  grid-column: 1;
  grid-row: 1;
  display: grid;
  align-self: center;
  justify-self: center;
  grid-gap: 5px;
  justify-self: start;
}

.selectedBack {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  align-self: center;
  justify-self: center;
  margin: 0 10px;
  font-size: 0.825rem;
}

.selectedsTotal {
  grid-column: 2;
  grid-row: 1;
  display: grid;
}

.selectedNumber {
  grid-column: 1;
  grid-row: 1;
  width: 1em;
  height: 1em;
  margin-right: -5px;
  color: #125ad3;
  font-size: 1.2rem;
  font-weight: 600;
}

.buttonGroup {
  display: grid;
  grid-column: 3;
  grid-row: 1;
  grid-template-columns: repeat(3, auto);
  align-self: center;
  justify-self: center;
}

.statusBtnTitle {
  grid-column: 2;
  grid-row: 1;
  align-self: center;
  justify-self: center;
  font-size: 0.625rem;
  margin-left: 5px;
}

.dialogTitle {
  margin-bottom: 20px;
  text-align: center;
}

.notes {
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .selectToolbar {
    margin: 7px 0;
    height: 40px;
  }
}

@media only screen and (max-width: 400px) {
  .statusBtnTitle {
    grid-column: 1;
    grid-row: 2;
  }
}
