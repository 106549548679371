.boxDate {
  grid-row: 1;
  display: grid;
  background-color: #fff;
  border-radius: 5px;
  border-color: #e1e1e1;
  border-width: 1px;
  border-style: solid;
  height: 100px;
}

.date {
  display: grid;
  align-self: center;
  justify-self: center;
  font-size: 36px;
  text-align: center;
  color: #747474;
  margin: 10px;
}

.hourDisplay {
  font-size: 1.3rem;
}

.dateDisplay {
  font-size: 0.9rem;
  text-transform: lowercase;
}

@media only screen and (max-width: 600px) {
  .boxDate {
    grid-column: 1;
    grid-row: 1;
    border-radius: 0px;
    border-width: 0 1px 0 0;
  }
  .hourDisplay {
    font-size: 1rem;
  }

  .dateDisplay {
    font-size: 0.8rem;
  }
}

@media only screen and (max-height: 480px) {
  .boxDate {
    grid-column: 1;
    grid-row: 1;
    border-radius: 0px;
    border-width: 0 1px 0 0;
  }
  .hourDisplay {
    font-size: 1rem;
  }

  .dateDisplay {
    font-size: 0.8rem;
  }
}
