.chartContent {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #e1e1e1;
  user-select: none;
}

.boxSkeleton {
  display: grid;
  grid-gap: 5px;
  grid-template-rows: min-content min-content;
}

.sklChartHeader {
  grid-row: 1;
}

.sklChart {
  grid-row: 2;
}

.chartHeader {
  grid-row: 1;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: 1fr;
  padding: 10px;
  user-select: none;
}

.chartTitle {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  font-weight: 600;
  color: #6a6a6a;
  user-select: none;
}

.chartButtons {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  justify-self: end;
  user-select: none;
}

.chartBody {
  grid-row: 2;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .chartContent {
    border-radius: 0;
  }
}

@media only screen and (max-height: 480px) {
  .chartContent {
    border-radius: 0;
  }
}
