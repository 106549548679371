.listSearch {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  min-height: 80px;
  background-color: transparent;
  user-select: none;
}

.searchContainer {
  height: 50px;
  align-self: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0),
    0px 1px 10px 0px rgba(0, 0, 0, 0.04);
  min-height: 0;
  border-radius: 4px;
  background-color: #fff;
  user-select: none;
}

.search {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  grid-template-rows: 50px;
  user-select: none;
}

.filterMenu {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-rows: min-content;
  align-self: center;
  justify-self: center;
  margin: 0 10px;
}

.iconSearch {
  grid-column: 2;
  align-self: center;
  justify-self: center;
  margin: 0 10px;
  user-select: none;
}

.inputSearch {
  grid-column: 3;
  align-self: center;
  justify-self: center;
  user-select: none;
}

@media only screen and (max-width: 1280px) {
  .iconSearch {
    margin: 0 10px 0 0;
  }
}
@media only screen and (max-height: 480px) {
  .searchContainer {
    height: 40px;
  }

  .listSearch {
    min-height: 55px;
  }

  .search {
    grid-template-rows: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .searchContainer {
    height: 40px;
  }

  .listSearch {
    min-height: 55px;
  }

  .search {
    grid-template-rows: 40px;
  }
}
