.footer {
  grid-area: footer;
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: 1fr;
  min-height: 55px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.1), 0px 4px 4px 0px rgba(0, 0, 0, 0.1),
    0px 1px 10px 0px rgba(0, 0, 0, 0.5);
  z-index: 3;
  user-select: none;
}

.menu {
  grid-row: 1;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(3, 65px);
  grid-template-rows: 1fr;
  grid-gap: 20px;
  user-select: none;
}

.menuItem {
  grid-row: 1;
  align-self: center;
  justify-self: center;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-gap: 2px;
  text-decoration: none;
  color: #737373;
  font-weight: 400;
}

.menuTitle {
  grid-row: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.625rem;
}

.menuSelected {
  grid-row: 3;
  width: 65px;
  height: 0.3em;
  position: relative;
  transition: all 900ms cubic-bezier(0.4, 0, 0.2, 1) 300ms;
}
