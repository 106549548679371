.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-image: url("../../Image/background_sisea.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
  max-height: 100vh;
  user-select: none;
}

.error {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  align-self: center;
  justify-self: center;
  user-select: none;
}

.errorTitle {
  grid-row: 1;
  align-self: center;
  justify-self: center;
  margin-bottom: 20px;
  font-size: calc(18px + 3vw);
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  user-select: none;
}

.errorMsg {
  grid-row: 2;
  align-self: center;
  justify-self: center;
  font-size: calc(15px + 0.3vw);
  color: #fff;
  text-align: center;
  user-select: none;
}

.goLogin {
  grid-row: 3;
  justify-self: center;
  align-self: start;
  margin: 20px;
  font-size: calc(16px + 0.5vw);
  text-decoration: none;
  color: #bbd4ff;
  user-select: none;
}

.goLogin:hover {
  text-decoration: underline;
  user-select: none;
}
