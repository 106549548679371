.authContainer {
  display: grid;
  /* background-color: #2f354a; */
  background-image: url("../../Image/background_sisea.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: auto;
  max-height: 100vh;
  user-select: none;
}

.authMain {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto 1fr;
  align-self: center;
  justify-self: center;
  height: 450px;
  padding: 20px 0;
  background-color: #fff;
  min-width: 280px;
  max-width: 320px;
  border-radius: 5px;
  box-shadow: 0px 1px 8px 4px rgba(0, 0, 0, 0.4);
  user-select: none;
}

.loginLogo {
  grid-row: 2;
  width: 150px;
  justify-self: center;
  margin: 20px 0 40px;
  user-select: none;
}

.version {
  align-self: end;
  justify-self: end;
  color: #7babff;
}
