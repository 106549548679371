.main {
  grid-area: main;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  max-width: 100%;

  user-select: none;
}

.contactToolbar {
  grid-row: 1;
  grid-column: 1 / span 2;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: linear-gradient(to right, #fbfbfb 0%, #eaeaea 100%);
  pointer-events: none;
  background-size: cover;
  user-select: none;
}

.contactMain {
  grid-row: 1 / span 2;
  grid-column: 1 / span 2;
  display: grid;
  grid-template-columns: 240px 1fr;
  user-select: none;
}

.contactSidebar {
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 100%;
  user-select: none;
}

.contactList {
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  padding: 0 10px 0 0;
  z-index: 2;
  user-select: none;
}

.alphabeticFilter {
  grid-row: 2;
  grid-column: 2;
  width: 25px;
  margin-top: 45px;
  background-color: rgba(214, 210, 210, 0.44);
  overflow: hidden;
  border-radius: 0 6px 6px 0;
}

@media only screen and (max-width: 1280px) {
  .contactMain {
    grid-template-columns: 1fr;
  }

  .contactList {
    grid-column: 1;
    padding: 0 10px;
  }
  .contactSidebar {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .contactList {
    padding: 0 5px;
  }
}
