body {
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: grid;
}

#root,
body,
html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  #root,
  body,
  html {
    font-size: 95%;
  }
}
