.colorBar {
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  grid-gap: 10px;
  margin: 20px 0;
}

.color {
  width: 50px;
  height: 20px;
  display: grid;
  align-content: center;
  justify-content: center;
  cursor: pointer;
}
