.header {
  display: grid;
  grid-template-columns: auto 40px 40px;
  grid-gap: 0 10px;
}

.loading {
  grid-row: 2;
  width: 100%;
  height: 5px;
  background-color: #e8e8e8;
}
.panelSup {
  display: grid;
  grid-auto-columns: auto 1fr;
  background-color: #fbfbfb;
  padding-right: 24px;
}

.date {
  display: grid;
  grid-template-rows: 1fr auto;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  margin-right: 10px;
  width: 50px;
  height: 100%;
  background-color: #0899bb;
  user-select: none;
}

.itemDayMonth {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: center;
  color: #fff;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  width: 20px;
  user-select: none;
}

.itemDateYear {
  display: grid;
  background-color: #c5c5c5;
  grid-template-rows: 1fr;
  grid-row: 2;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  width: 100%;
  height: 100%;
  user-select: none;
}

.itemYear {
  grid-row: 1;
  grid-column: 1;
  align-self: end;
  justify-self: center;
  padding: 4px;
  color: #fff;
  user-select: none;
}

.infoProcess {
  grid-row: 1;
  grid-column: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin: 20px 0;
}

.client {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  user-select: none;
}

.clientName {
  grid-row: 1;
  grid-column: 2;
  font-size: 1rem;
  font-weight: 600;
}

.folder {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}

.folderNumber {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
}

.panelInf {
  display: grid;
  grid-auto-columns: 1fr;
  grid-gap: 20px;
  margin: 20px 0;
  padding: 8px 24px;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}

.content {
  grid-row: 1;
  grid-column: 2;
  font-size: 0.9rem;
  display: grid;
}

.label {
  grid-row: 1;
  font-size: 0.625rem;
  color: #737373;
}

.textContent {
  grid-row: 2;
  font-size: 0.9rem;
  line-height: 1.5;
  word-wrap: anywhere;
  white-space: pre-line;
}

.dialogTitle {
  margin: 0px 5px 20px;
  text-align: center;
}
