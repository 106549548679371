.topbar {
  grid-area: topbar;
  display: grid;
  grid-template-columns: min-content min-content;
  min-height: 35px;
  max-height: 55px;
  background-color: #f5f5f5;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05),
    0px 1px 10px 0px rgba(0, 0, 0, 0.05);
  z-index: 3;
  user-select: none;
}

.topBarLogo {
  grid-row: 1;
  grid-column: 1;
  display: grid;
  background-color: #125ad3;
  max-width: 150px;
  max-height: 55px;
  user-select: none;
}

.imgLogo {
  width: 12vmin;
  justify-self: center;
  align-self: center;
  margin: 0px 20px;
  max-width: 75px;
  min-width: 50px;
  user-select: none;
}

.topBarProfile {
  grid-row: 1;
  grid-column: 3;
  align-self: center;
  justify-self: end;
  margin: 0 10px;
  user-select: none;
}

.usernameProfile {
  margin: 0 2px 0 10px;
  font-weight: 400;
  line-height: 0.9;
  text-align: center;
  user-select: none;
}
