.containerRecentCases {
  display: grid;
  grid-template-rows: 40px 1fr;
  margin: 20px 0;
}

.contentTitle {
  grid-row: 1;
  grid-column: 1;
  margin: 0 20px 10px;
  font-size: 1.1em;
  font-weight: 600;
  color: #737373;
  user-select: none;
}

.listRecentCases {
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-auto-rows: minmax(20px, 50px);
  overflow-y: auto;
  max-height: 300px;
  background-color: #f7f7f7;
  grid-gap: 5px;
  padding: 20px;
  user-select: none;
}

.viewCase {
  grid-column: 1;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02857em;
  color: #125ad3;
  font-weight: 600;
  height: 16px;
  padding: 4px;
  cursor: pointer;
  user-select: none;
}

.viewCase:hover {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 4px;
  border-radius: 4px;
  user-select: none;
}

.itemRecentCases {
  display: grid;
  grid-column: 1;
  grid-template-columns: 1fr max-content;
  grid-gap: 15px;
  width: max-content;
  user-select: none;
}
