.listItem {
  display: grid;
  grid-template-columns: repeat(4, auto) 1fr auto;
  user-select: none;
}

.listItemInfo {
  display: grid;
  grid-column: 1;
  align-self: center;
  justify-self: start;
  margin-left: 10px;
  grid-gap: 5px;
  user-select: none;
}

.itemName {
  grid-row: 1;
  grid-column: 1;
  font-size: 0.925rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
  color: #747474;
  user-select: none;
}

.itemContactOrigin {
  grid-row: 1;
  grid-column: 2;
  justify-self: end;
  align-self: start;
  padding: 5px;
  user-select: none;
}

.itemEmail {
  grid-row: 2;
  color: rgba(0, 0, 0, 0.55);
  font-size: 0.88rem;
  font-weight: 400;
  line-height: 1.5;
  text-decoration: none;
  user-select: none;
}

.listItemPhone {
  display: grid;
  grid-template-columns: max-content max-content;
  grid-row: 3;
  grid-gap: 15px;
  align-self: stretch;
  justify-self: start;
  user-select: none;
}

.itemPhone {
  grid-column: 1;
  display: grid;
  grid-gap: 5px;
  align-self: center;
  justify-self: center;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.55);
  user-select: none;
}

.itemCellPhone {
  grid-column: 2;
  display: grid;
  grid-gap: 5px;
  align-self: center;
  justify-self: center;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.55);
  user-select: none;
}

.phoneNumber {
  grid-row: 1;
  grid-column: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.8rem;
  user-select: none;
}

.originTitle {
  grid-row: 2;
  align-self: center;
  justify-self: center;
  font-size: 0.65rem;
  color: #747474;
}
